// Add anything custom here -- mixins, variable overrides.
// by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier

.swal2-container {
  z-index: 2060!important;
}

.cvs {
  padding: 1rem 1rem;
  opacity: 0.8;
  font-weight: 400;
  font-size: 0.875rem;
  border-radius: 0px 0px 8px 0px;

  &-left {
    margin-bottom: 1rem;
    border-left: 3px solid #1dc9b7;
    background: rgba(29, 201, 183, 0.1);

    &-adm {
      border-radius: 0px 8px 0px 0px;
      margin-bottom: 0;
    }
  }

  &-adm {
    margin-bottom: 1rem;
    border-left: 3px solid #7649e1;
    background: rgba(118, 73, 225, 0.1);
  };
}