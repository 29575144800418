/* Custom Webkit Scrollbar */
/* http://css-tricks.com/custom-scrollbars-in-webkit/ */

$trackPieceColor:                #efefef;
$handleBar:                      $fusion-100;
$handleBarHover:                 darken($handleBar, 10%);
$modalScrollSize:                8px;

body:not(.mobile-detected),
body:not(.mobile-detected).modal-open .modal {
    &::-webkit-scrollbar {
        height: $modalScrollSize;
        width: $modalScrollSize;
    }

    &::-webkit-scrollbar:hover{
    	background-color:rgba(0,0,0,.01)
    }

    &::-webkit-scrollbar-track-piece {
        background-color: $trackPieceColor;
    }

    &::-webkit-scrollbar-track-piece:hover {
        background-color: darken($trackPieceColor, 12%);
    }

    &::-webkit-scrollbar-thumb:vertical {
        background-color: $handleBar;
    }

    &::-webkit-scrollbar-thumb:vertical:hover {
        background-color: $handleBarHover;
    }
}

/* 
 * Left Panel custom scroll 
 */

.page-sidebar .primary-nav::-webkit-scrollbar-track-piece {
    background-color: $trackPieceColor;
}

.page-sidebar .primary-nav::-webkit-scrollbar-thumb:vertical {
    background-color: #666;
}

.page-sidebar .primary-nav::-webkit-scrollbar {
    height: $modalScrollSize*0.5;
    width: $modalScrollSize*0.5;
}

.page-sidebar .primary-nav:hover::-webkit-scrollbar-corner {
    width: 40px;
}

.page-sidebar .primary-nav::-webkit-scrollbar-track-piece {
	background-color: $trackPieceColor;
}

.page-sidebar .primary-nav::-webkit-scrollbar-thumb:vertical {
	background-color: #666;
}