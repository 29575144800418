/**
JQuery Validation Plugin
 */

[id$="-error"] {
  //@extend .text-danger;
  position: absolute;
  top: -2px;
  right: 4px;
  background-color: $danger-50;
  color: $danger;
  padding: 2px 4px;
  border-radius: $border-radius;
}