/* text area */
.form-content-editable[contenteditable="true"] {
	overflow:auto;
	-webkit-user-modify: read-write-plaintext-only;

	line-height: normal;

	&:focus {
		outline: 0;
	}

	&:empty:not(:focus):before{
		content: attr(data-placeholder);
		color: $fusion-50;
	}

	&::selection {
		background: rgba(0, 132, 255, .2);
		color: $black;
	}

	&::-moz-selection {
		background: rgba(0, 132, 255, .2);
		color: $black;
	}
}

//why did we need this?
/*.form-control:not(.form-control-sm),
.custom-select:not(.custom-select-sm),
.input-group:not(.input-group-sm) {
	min-height: calc(2.25rem + 2px);
}*/

.form-label {
	font-weight: 500;
}

/* select arrow */

/*select:not(.custom-select):not([multiple]) {
  background-image:
    linear-gradient(45deg, transparent 50%, red 60%),
    linear-gradient(135deg, red 40%, transparent 50%) !important;
  background-position:
    calc(100% - 30px) 14px,
    calc(100% - 20px) 14px,
    100% 0;
  background-size:
    10px 10px,
    10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}*/

/* fix */
.custom-range {
	-webkit-appearance: none;    
	-moz-appearance: none;
}
.custom-range::-moz-range-thumb {
	-moz-appearance: none;
}
.custom-range::-webkit-slider-thumb {
	-webkit-appearance: none;
}

/* add background to focused inpur prepend and append */
.form-control:focus ~ .input-group-prepend {
	background: $primary-500;
}
.has-length {
	.input-group-text {
		border-color: $primary-500;
		& + .input-group-text {
			border-left: 1px solid rgba($black, 0.1);
		}
	}
	.input-group-text:not([class^="bg-"]):not([class*=" bg-"]) {
		background: $primary-500;
		color: $white !important;
	}

}
.input-group-text {
	transition: all 0.15s ease-in-out, 
}

/* input group animation for multiple inputs */
.input-group.input-group-multi-transition {
	input[type="text"] {

		transition: width 470ms cubic-bezier(0.34, 1.25, 0.3, 1);
		&:focus {
			width:50%;
		}
	}
}

/* BS form hack for checkbox tick mark */
.custom-checkbox .custom-control-label::after {
	background-size: $custom-control-indicator-bg-size-checkbox;
}

/* circle checkbox */
.custom-checkbox-circle {
	.custom-control-label:before {
		border-radius: 50%;
	}
}
/* rounded radio */
.custom-radio-rounded {
	.custom-control-label:before {
		border-radius: 4px;
	}
}

/* not sure if we need this? */
/*.custom-control {
	min-height: $custom-control-indicator-size;
}*/

/* make checked label bold */
input[type="radio"]:checked + .custom-control-label, 
input[type="checkbox"]:checked + .custom-control-label {
	font-weight: 500;
}

/* help block and validation feedback texts*/
.help-block {
	color: $fusion-50;
}

/* on feedback error */
.help-block, .invalid-feedback, .valid-feedback {
	font-size: rem($fs-nano);
	margin-top: 0.325rem;
}

/* when form group is last child show now margin */
.form-group:last-child,
.form-group:only-child {
	margin-bottom: 8px;
}

/* fix alignment for generic checkbox and radio */
.form-check-input {
	margin-top: 0;
} 
.form-check-label {
	display: flex;
	align-items: center;
}
.form-check {
	margin: 5px 0;
}